import {
  Divider,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import EmbossedCard from '../../../components/EmbossedCard'
import GuideHeader from '../../../components/navigation/GuideHeader'

import Layout from '../../../components/Layout'
import SeoHeader from '../../../components/SeoHeader'
import itemMapping from '../../../hooks/mapping/items.json'
import itemstatsMapping from '../../../hooks/mapping/itemstats.json'
import skillMapping from '../../../hooks/mapping/skills.json'
import specializationMapping from '../../../hooks/mapping/specializations.json'
import traitMapping from '../../../hooks/mapping/traits.json'

const layout = {
  SeoProps: {
    title: 'ID Helper',
    description:
      'Utilize the Discretize ID helper to easily find common Gw2 items, skills and traits.',
  },
  fabNavigation: true,
}

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: 20,
  },
  results: {
    maxHeight: '70vh',
    overflow: 'auto',
  },
  select: {
    width: '100%',
  },
}))

const tags = ['Items', 'Skills', 'Traits', 'Specializations', 'Itemstats']

const normalize = (string) =>
  string === undefined
    ? string
    : string
        .replace(/[^A-Za-z]/g, '')
        .toLowerCase()
        .trim()

function EditorIdsPage() {
  const { classes } = useStyles()

  const [state, setState] = React.useState({
    tag: 'Items',
    input: {},
  })

  const handleTagChange = ({ target: { value } }) =>
    setState({ tag: value, input: {} })

  const handleInputChange =
    (name) =>
    ({ target: { value } }) =>
      setState(({ tag, input }) => ({
        tag,
        input: { ...input, [name]: value },
      }))

  const renderForm = () => {
    const { tag, input } = state

    switch (tag) {
      case 'Items': {
        const { name, type, affix, weight } = input
        return (
          <form noValidate autoComplete="off">
            <TextField
              fullWidth
              value={name || ''}
              onChange={handleInputChange('name')}
              id="name"
              label="Name"
              margin="normal"
            />
            <TextField
              fullWidth
              value={type || ''}
              onChange={handleInputChange('type')}
              id="type"
              label="Type"
              margin="normal"
            />
            <TextField
              fullWidth
              value={affix || ''}
              onChange={handleInputChange('affix')}
              id="affix"
              label="Affix"
              margin="normal"
            />
            <TextField
              fullWidth
              value={weight || ''}
              onChange={handleInputChange('weight')}
              id="weight"
              label="Weight"
              margin="normal"
            />
          </form>
        )
      }
      case 'Skills':
      case 'Traits':
      case 'Specializations': {
        const { name, profession } = input
        return (
          <form noValidate autoComplete="off">
            <TextField
              fullWidth
              value={name || ''}
              onChange={handleInputChange('name')}
              id="name"
              label="Name"
              margin="normal"
            />
            <TextField
              fullWidth
              value={profession || ''}
              onChange={handleInputChange('profession')}
              id="profession"
              label="Profession"
              margin="normal"
            />
          </form>
        )
      }
      case 'Itemstats': {
        const { name } = input
        return (
          <form noValidate autoComplete="off">
            <TextField
              fullWidth
              value={name || ''}
              onChange={handleInputChange('name')}
              id="name"
              label="Name"
              margin="normal"
            />
          </form>
        )
      }
      default:
        return null
    }
  }

  const renderTableHead = () => {
    const { tag } = state

    switch (tag) {
      case 'Items':
        return (
          <TableHead>
            <TableRow>
              <TableCell align="right">Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Affix</TableCell>
              <TableCell>Weight</TableCell>
            </TableRow>
          </TableHead>
        )
      case 'Skills':
      case 'Traits':
      case 'Specializations':
        return (
          <TableHead>
            <TableRow>
              <TableCell align="right">Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Profession</TableCell>
            </TableRow>
          </TableHead>
        )
      case 'Itemstats':
        return (
          <TableHead>
            <TableRow>
              <TableCell align="right">Id</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
        )
      default:
        return null
    }
  }

  const getResults = () => {
    const { tag, input } = state

    let mapping
    switch (tag) {
      case 'Items':
        mapping = itemMapping
        break
      case 'Skills':
        mapping = skillMapping
        break
      case 'Traits':
        mapping = traitMapping
        break
      case 'Specializations':
        mapping = specializationMapping
        break
      case 'Itemstats':
        mapping = itemstatsMapping
        break
      default:
        return []
    }

    switch (tag) {
      case 'Items': {
        const {
          name: uName,
          type: uType,
          affix: uAffix,
          weight: uWeight,
        } = input

        const name = normalize(uName)
        const type = normalize(uType)
        const affix = normalize(uAffix)
        const weight = normalize(uWeight)

        return mapping.filter(
          ({
            name: itemName,
            type: itemType,
            affix: itemAffix,
            weight: itemWeight,
          }) =>
            (!name || (itemName && itemName.includes(name))) &&
            (!type || (itemType && itemType.includes(type))) &&
            (!affix || (itemAffix && itemAffix.includes(affix))) &&
            (!weight || (itemWeight && itemWeight.includes(weight))),
        )
      }
      case 'Skills':
      case 'Traits':
      case 'Specializations': {
        const { name: uName, profession: uProfession } = input

        const name = normalize(uName)
        const profession = normalize(uProfession)

        return mapping.filter(
          ({ name: iName, profession: iProfession }) =>
            (!name || (iName && iName.includes(name))) &&
            (!profession || (iProfession && iProfession.includes(profession))),
        )
      }
      case 'Itemstats': {
        const { name: uName } = input

        const name = normalize(uName)

        return mapping.filter(
          ({ name: iName }) => !name || (iName && iName.includes(name)),
        )
      }
      default:
        return []
    }
  }

  // using index as key to avoid UI corruption from duplicate IDs
  const renderTableRow = (item, index) => {
    const { tag } = state

    switch (tag) {
      case 'Items': {
        const { id, name, type, affix, weight } = item
        return (
          <TableRow key={index}>
            <TableCell align="right">{id}</TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>{type}</TableCell>
            <TableCell>{affix}</TableCell>
            <TableCell>{weight}</TableCell>
          </TableRow>
        )
      }
      case 'Skills':
      case 'Traits':
      case 'Specializations': {
        const { id, name, profession } = item
        return (
          <TableRow key={index}>
            <TableCell align="right">{id}</TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>{profession}</TableCell>
          </TableRow>
        )
      }
      case 'Itemstats': {
        const { ids, name } = item
        return (
          <TableRow key={index}>
            <TableCell align="right">{ids.join(', ')}</TableCell>
            <TableCell>{name}</TableCell>
          </TableRow>
        )
      }
      default:
        return null
    }
  }

  return (
    <div className={classes.root}>
      <GuideHeader title="Guild Wars 2 ID Helper" />
      <Divider />

      <Grid container spacing={5}>
        <Grid item xs={12} sm={3}>
          <EmbossedCard
            title={
              <Select
                className={classes.select}
                value={state.tag}
                onChange={handleTagChange}
                inputProps={{
                  name: 'tag',
                  id: 'tag-simple',
                }}
              >
                {tags.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            }
          >
            {renderForm()}
          </EmbossedCard>
        </Grid>

        <Grid item xs={12} sm={9}>
          <div className={classes.results}>
            <Table>
              {renderTableHead()}

              <TableBody>{getResults().map(renderTableRow)}</TableBody>
            </Table>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default function EditorIdsPageWrapper({ location }) {
  return (
    <Layout location={location}>
      <SeoHeader path={location.pathname} {...layout.SeoProps} />

      <EditorIdsPage />
    </Layout>
  )
}
